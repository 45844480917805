
/* main banner container that split navbar and content */
.upload-main-container{
    width:100%;
    height:100vh;
    display: flex;
   
    
    
}

/* sidebar-container */


.uploadbanner-container{
    width:85%;
  
  
}

/* banner image preivew section =========================================================== */
/* //////////////////////////////////////////////////////////////////////////////////////////// */
.banner-upload-section{
    width:90%;
    height:65vh;
   
    margin:auto;
    margin-top:1%;
}
/* hide the default button file */
.uploadbannerbutton{
    display: none;
}

/* create a another selection file customzing */
.upload-container-section{
    background-color: rgb(238, 238, 238);
    width:100%;
    height:100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}
.preview-img-section{
   
    width:100%;
    height:100%;
    position: absolute;
    top:0px;
    z-index: 999999;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
/* customize the image icon of file  */

.dotted-border{
    border:3px dotted rgb(255, 151, 151);
    width:95%;
    height:90%;
    position: absolute;

}
.fa-file-arrow-up{
    font-size:4.5rem;
    transition:all 0.5s ease-in-out;
}
.upload-container-section:hover .fa-file-arrow-up{
    transform: scale(1.2);
    color:rgb(255, 110, 110);

}
.upload-container-section p{
    font-size:1.6rem;
    margin:10px 0px;
    color:rgb(201, 201, 201);
    font-family: Arial, Helvetica, sans-serif;

}
.upload-container-section p > span{
    color:rgb(255, 64, 64);
    font-weight: bold;
}
/* ?//////////////////////////////////////////////////////////////////////////////////////////////// */


/* second section ==================================================== */
/* ///////////////////////////////////////////// */
.banner-upload-button-section{
 
    width:90%;
    margin:auto;
}


/* /////////////////////////////////Image Size check ============================================ */
.file-size-container{
   
    
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding:10px 0px;
 
}

/* cancle preivew ============================================== */
.cancle-preview{
    position: absolute;
    top:6px;
    left:0px;
    background-color: rgb(61, 61, 61);
    color:white;
    width:15%;
    display: flex;
    justify-content: center;
    padding:6px 0px;
    cursor: pointer;
    transition:all 0.4s ease;
}
.cancle-preview:hover{
    background-color: red;
}
/* ///////////////////////////////////////////////////////////////////// */

.file-size-check{
    width:50%;
   

}
.file-size-inner{
    background: rgb(111,181,230);
    background: linear-gradient(90deg, rgba(111,181,230,1) 0%, rgba(133,255,128,1) 0%, rgba(139,255,158,1) 50%, rgba(241,211,255,1) 50%, rgba(63,136,251,1) 50%, rgba(82,143,255,1) 80%, rgba(255,71,71,1) 80%, rgba(255,0,0,1) 100%); 
    width:100%;
    height:15px;
    position: relative;
}
.file-size-description{
    width:100%;
    display: flex;
    justify-content: center;
    font-size:12px;
    position: relative;
}

.fa-location-dot{
    position: absolute;
    top:-10px;
    animation-name: filesizeanimation;
    animation-duration: 1s;
    transition:all 0.5s ease;
    left:0%;
}
/* file size picker animation */
@keyframes filesizeanimation{
    0%{
       left:0%;
    }
}
.file-size-description p:nth-child(1){
 
    left:20%;
    position: absolute;  
}
.file-size-description p:nth-child(2){

    left:60%;
    position: absolute;  
}
.file-size-description p:nth-child(3){
    left:88%;
    position: absolute;  
}
/* ////////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// */

/* banner-progress-container//////////////////////// */
.banner-progress-container{
    width:100%;
 
    display: flex;
    justify-content:flex-start;
    align-items: center;
    margin-top:10px;

    
}



.banner-upload-icon > i{
    font-size:50px;
    color:rgb(222, 222, 222);
}


.banner-progress-bar{
    width:50%;
    display: flex;
    flex-direction:column;
    justify-content: center;

    padding:15px 20px;
    

}
.banner-progress-bar p{
  
    margin:0px;
    font-size:12px;
    color:grey;
}



.banner-progress-bar .progress-bar{
    width: 100%;
    height:15px;
    background-color: rgb(223, 223, 223);
    border-radius:2px;
    position: relative;
}
.banner-progress-bar  .inner-bar{
    width:50%;
    height: 100%;
    transition:all 1s ease;
    background-color: rgb(38, 92, 218);
    display: flex;
    justify-content: center;
    align-items: center;
}
.inner-bar p{
    color:white;
    font-size:8px;
    

}
/* ///////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////// */

/* button submit */
.uploadbanner-container button{
    border:0px;
    background-color: rgb(189, 189, 189);
    color:rgb(227, 227, 227);
    padding:10px 0px;
    width:100%;
    border-radius:10px;
    font-weight: bold;
    transition:all 1s ease;
}
.uploadbanner-container button:hover{
    background-color: rgb(61, 61, 61);
    color:white;
}
/* ////////////////// */