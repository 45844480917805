
.admin-gallery-main-container{
    width:100%;
    display: flex;
}
.admin-gallery-container{
    width:100%;
    display: flex;

}
.admin-gallery-container > div:nth-child(1){
    width:70%;
   
}
.category-gallery-container-main{
    position: relative;
}

.gallery-submit-container{
    width:30%;
  
    position: relative;

}
.gallery-submit-container form{
    width:85%;
    margin:20px auto;

}
.gallery-submit-container form select{
    width:100%;
    margin:10px 0px;
    padding:5px;
    border:none;
    background-color: rgb(223, 223, 223);
}
.gallery-submit-container form input{
    width:100%;
    margin:10px 0px;
    padding:5px;
    border:none;
    background-color: rgb(223, 223, 223);
}
.admin-gallery-preview-cancle{
     position: absolute;
     top:24%;
     left:5px;
}
.admin-gallery-preview-cancle i{
    background-color: rgb(59, 59, 59);
    padding:10px 4px;
    font-size:10px;
    color:white;
}

.admin-gallery-preview-cancle i:hover{
    background-color: rgb(242, 89, 89);
    cursor: pointer;
 
}
.gallery-submit-container form button{
    width:100%;
    margin:10px 0px;

    padding:5px;
    border:none;
    background-color: rgb(119, 41, 175);
    color:white;
    font-weight: bold;
}
.add-category-add-photo-selection{
    width:85%;
    display: flex;
    justify-content:flex-end;
    align-items:flex-end;
    height:20vh;
    margin:auto;
}

.add-category-photo-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width:55%;
    height:25px;
    position: relative;
    margin:10px 0px;
}
.add-category-photo{
    width:100%;
    height:100%;
    padding:0 10px;
    border:none;
    appearance: none;
    background-color: rgb(28, 28, 28);
    color:white;
    font-size:11px;
    font-weight: 500;
    letter-spacing:0.5px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
}
.add-category-photo option * {
    background-color: rgb(63, 63, 63);
    padding:10px 0px;
    height:20px;
}
.add-category-photo:focus{
    outline: none;
}
.category-photo-drop-down-icon{
    width:30px;
    height:100%;
    position: absolute;
    right:0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(255, 255, 255);
    font-size:20px;
    background-color: rgb(92, 92, 92);
}



/* scroll tab container  */

.category-scrollbar-tab-container{
    background-color: rgb(254, 232, 176);
    width:100%;
    margin:8px auto;
    border-radius:6px;
    font-family:"Roboto","sans-serif";
    position: relative;
}
.right-arrow i,
.left-arrow i{
    
    padding:4px 10px;
    
    cursor: pointer;
    color:rgb(110, 96, 96);
    /* pointer-events: auto; */
    /* background-color: rgb(110, 110, 110); */
    

   
    
}
.right-arrow,
.left-arrow{
    position: absolute;
    height:100%;
    width:100px;
    top:0px;
    display: flex;
    align-items: center;
    padding:10px 10px;
    display: none;
    /* pointer-events: none; */
    
    
 
    
}
.right-arrow.active,
.left-arrow.active{
        display: flex;
}
.right-arrow i:hover,
.left-arrow i:hover{
   background-color:#363535 ;
   border-radius:3px;
   color:white;
 
    
}
.left-arrow{
    background:linear-gradient(to right,rgb(255, 221, 136) 50%,transparent);
    border-radius:10px 0px 0px 10px;

}
.right-arrow{
    right:0px;
    justify-content: flex-end;
    background:linear-gradient(to left,#ffe091 50%,transparent);
    border-radius:0px 10px 10px 0px;
}
.category-scrollbar-tab-container ul{
    display: flex;
    gap:16px;
    padding:9px 20px;
    margin:0px;
    list-style-type: none;
    overflow-x: scroll;
    -ms-overflow-style:none;
    scrollbar-width: none;
    scroll-behavior: smooth;

}
.category-scrollbar-tab-container ul.dragging{
    scroll-behavior: auto;
}
.category-scrollbar-tab-container li{
    font-size:12px;
}
.category-scrollbar-tab-container a{
    color:rgb(78, 78, 78);
    text-decoration: none;
    background-color:rgb(254, 210, 114);
    padding:2px 20px;
    display: inline-block;
    border-radius:4px;
    user-select: none;
    white-space: nowrap;
    font-weight: bold;
    /* color:rgb(78, 77, 77); */
    font-family: Arial, Helvetica, sans-serif;


}
.category-scrollbar-tab-container ul li a.active{
    background-color: rgb(50, 50, 50);
    color:white;
}


/* photo-gallery-containe */
.photo-gallery-container{
    width:100%;
    height:82vh;
    display: grid;
    grid-template-columns:repeat(5,1fr);
    grid-gap:5px;
    overflow-y: scroll;
    padding:10px;
    grid-auto-rows:200px;
    
}
.photo-gallery-container div{
    width:100%;
    height:100%;
    position: relative;
}
.photo-gallery-container img{
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius:10px;
    filter:brightness(0.8);
   
    
}
.photo-gallery-container i{
    position: absolute;
    top:10px;
    right:10px;
    background-color:rgb(242, 86, 86);
    font-size:13px;
    padding:5px;
    color:white;
    cursor: pointer;
    border-radius:3px;
}
.photo-gallery-container i:hover{
    background-color: red;
}

.total-photo-count{
    width:100%;
 
    display: flex;
    padding:3px 13px;
    font-size:14px;
    align-items: center;
  
   
}
.total-photo-count p:nth-child(1){
    font-size:20px;
    font-weight: bold;
}
.total-photo-count *{
    margin:0px 5px;
    
}

/* edit  */
.edit-category-container{
    position: absolute;
    background-color:rgb(47, 47, 47);
    z-index: 99999999999;
    width:200px;
    /* height:300px; */
    color:white;
    padding:10px;

    
}
.edit-context-category{
    width:100%;
    white-space:nowrap;
    font-size: 12px;

}
.edit-context-category p{
    font-size:11px;
    margin:5px 0px;
    font-weight: bold;
    text-transform: uppercase;
}
.edit-context-category p span{
    color:red;
    color:black;
    background-color:rgb(255, 255, 98);
    padding:3px;
}

.edit-context-replace-category-container form{
    width:100%;
    display: flex;
    flex-direction: column;
    
}
.edit-context-replace-category-container  input{
    width:100%;
    background-color: none;
    border-radius:none;
    
    background-color: rgb(74, 74, 74);
    outline: none;
    border:none;
    padding:5px;
    margin:4px 0px;
    color:rgb(209, 209, 209);
    font-weight: bold;
    
}
.edit-context-replace-category-container input::placeholder{
    color:rgb(199, 199, 199);
  
}
.edit-context-replace-category-container button{
    border:none;
    background-color: purple;
    color:white;
    font-weight: bold;
    padding:3px 0px;
}
.editcontext-category-close{
    position: absolute;
    top:10px;
    right:10px;

    
}
.editcontext-category-close i{
    background-color:grey;
    padding:3px 5px;
    cursor: pointer;

}
.editcontext-category-close i:hover{
    background-color:rgb(248, 248, 248);
    color:black;

}
.editcontext-delete-including-photo{
    width:100%;
}
.editcontext-delete-including-photo button{
    width:100%;
    background-color: rgb(255, 78, 78);
    border:none;
    color:white;
    margin-top:20px;
    padding:7px;
    font-weight: bold;
}

/* message */

.category-submit-message p{
    background-color: rgb(255, 54, 54);
    padding:4px 6px;
    color:white;
    border-radius:3px;
    font-weight:600;
    font-size: 12px;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

}
@-webkit-keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
    }
    60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
    }
    100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
    }
    60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97);
    }
    100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    } 








.category-submit-sucess-message{
    position: absolute;
    top:4%;
    right:10px;
   
}
.category-submit-sucess-message p{
    background-color: rgb(97, 61, 227);
    color:white;
    padding:7px 10px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 2px;
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    
}
@-webkit-keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
    }
    60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    }
    75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    }
    90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
    }
    60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    }
    75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    }
    90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
    } 


    /* gallery-main-loader */
    .gallery-main-loader{
        width:100%;
        height:100%;
        background-color: rgba(0, 0, 0, 0.127);
        position: absolute;
    }