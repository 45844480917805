

.news-main-container{
    width:88%;
    height:100vh;
 
    display: flex;
   
}

.news-show-container{
    width:40%;
    background-color: rgb(235, 235, 235);

}
.news-upload-container{
    width:60%;
    position: relative;
  
}

/* news preview cancle =================================== */
.news-preview-cancle{
    position: absolute;
    top:5px;
    left:20px;
    background-color: rgb(45, 45, 45);
    color:white;
    padding:5px;
    cursor: pointer;
    transition:all 0.5s ease;
}
.news-preview-cancle:hover{
    background-color: red;
}
/* //////////////////////////////////////////////////////// */

/* news form =========================================== */
/* /////////////////////////////////////////////////////////// */
.news-title-date-container{
    width:85%;
    margin:auto;
    /* border:1px solid red; */
    display: flex;
    height:15vh;
    align-items:center;
    justify-content:flex-start;
    
}
.news-title-date-container div{
    width:100%;

    display: flex;
    flex-direction: column;
    
    
}

.news-title-date-container input{
        width:95%;
        border:none;
        background-color: rgb(241, 241, 241);
        padding:5px;
        border-radius:0px;
        text-indent: 10px;
        color:rgb(108, 108, 108);

      

        
}
.news-title-date-container input:focus{
    outline:2px solid rgb(196, 167, 255);

}
.news-title-date-container input::placeholder{
    color:rgb(194, 194, 194);
}
.new-des-container{
  
    height:40vh;
    width:85%;
    margin:auto;
    
}
.new-des-container textarea{
    width:100%;
    height:100%;
    background-color: rgb(234, 234, 234);
    border:none;
    padding:10px;
    
}
.new-des-container textarea::placeholder{
    color:rgb(185, 185, 185);
}

.new-des-container textarea:focus{
    outline:2px solid rgb(196, 167, 255);
    border-radius:0px;
}
.news-submit-button{
    width:85%;
    margin:10px auto;
    
}
.news-submit-button button{
    width:100%;
    background-color: rgb(71, 27, 152);
    color:white;
    text-transform: uppercase;
    border:none;
    padding:5px 0px;

}
.news-submit-button button:hover{
    background-color: rgb(114, 66, 205);
}
/* /////////////////////////////////////////////////////////////////// */

/* loading section  */
.loading-container{
  
    width:100%;
    height:100%;
    z-index:99999999999;
    background-color: rgba(0, 0, 0,0.1);
    position: absolute;
  
    
    
}
/* ////////////////////////////////// */
/* sucessfullupload_message */
.sucessfullupload_message{
    position: absolute;
    top:4%;
    right:4%;
    transform: translate(-50%,-50%);

    color:white;
    z-index:99999999999999999;
    animation:news-success-animation 3s ease 0s 1 normal forwards;
    transition:all 4s ease-in;
    
    
    
}
.sucessfullupload_message p{
    font-size:12px;
    padding:10px 20px;
    border-radius:4px;
    background-color: orange;
    text-transform: uppercase;
    font-weight: bold;
    
}
.sucessfullupload_message p i{
    margin:0px 10px;
    font-size:15px;
    color:rgb(42, 85, 213);
}
@keyframes  news-success-animation{
    0%,
	100% {
		transform: translateX(0);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateX(-10px);
	}

	20%,
	40%,
	60% {
		transform: translateX(10px);
	}

	80% {
		transform: translateX(8px);
	}

	90% {
		transform: translateX(-8px);
	}
}


/* /////////////////////////////// */




/* news_error_message */
.news_error_message{
    position: absolute;
    top:10px;
    right:20px;
    color:white;
    z-index:99999999999999999;
    animation:news-success-animation 3s ease 0s 1 normal forwards;
    transition:all 4s ease-in;

}
.news_error_message p{
    font-size:10px;
    background-color: rgb(255, 39, 39);
    text-transform: uppercase;
    font-weight: bold;
    padding:10px;
}

/* /////////////////////// */
/* ///////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////// */
/* GET NEWS SHOW NEWS//////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////// */
.news-show-container{
    overflow: auto;
    overflow-x: hidden;
    background-color: rgb(255, 255, 255);
    position: relative;

  
}

/* delete a news */
.delete-news{
    position: absolute;

    right:5px;
    top:5px;
   
    cursor: pointer;
  
 
    
}
.delete-news i{
    margin:0px 5px;
    border-radius:3px;
    padding:10px 7px;
    background-color:rgb(255, 20, 20);
    color:white;
}
.delete-news i:nth-child(1){
    background-color:rgb(42, 55, 225);
}
.delete-news i:nth-child(1):hover{
    background-color: rgb(122, 110, 255);
    
}
.delete-news i:nth-child(2):hover{
    background-color: rgb(255, 77, 77);
}
/* //////////// */

.news-top-gap{
   
    height:10vh;
}
/* total-news */

.total-news{
 
    background-color: rgb(255, 255, 255);
    position:fixed;
    top:0px;
    width:32%;
    font-weight: bold;
    display: block;
    font-size:15px;
    padding:0px 40px;
    text-transform: uppercase;
    color:rgb(255, 184, 29);
    font-family: Arial, Helvetica, sans-serif;
    z-index:999999;
    
    
}

.total-news p{
    /* border:1px solid red; */
    margin:0px;
}
.total-news p:nth-child(1){
    font-size: 25px;
    color:rgb(255, 85, 0);
    position: relative;
    
    
    
}
.total-news p:nth-child(1)::after{
    content:"";
    background-color: rgb(31, 57, 141);
    position: absolute;
    width:13%;
    height:2px;
    bottom: 0px;
    left:0px;
    
}
/* /////////////// */
.news-box-container{
    width:80%;
    background-color: rgb(236, 236, 236);
    margin:10px auto;
    position: relative;


}
.news-box-photo-parent{
    width:100%;
    height:22vh;
    
}
.news-box-photo-parent img{
    width:100%;
    height:100%;
    object-fit:cover;
  

}
.news-box-container .title-date{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:3px 10px; 
    /* border:1px solid red; */

}
.title-descrition{  
    margin:5px 0px;
}
.title-descrition .title-date> h5{
    font-size:18px;
    text-transform: uppercase;
    color:rgb(55, 51, 135);
    
}
.title-descrition .title-date > p{
    font-size:11px;
}
.title-descrition .news-des{

    padding:10px 10px;
    font-size:14px;
    text-align:left;
    overflow: hidden;
}

.news-read-more{
    color:rgb(41, 39, 160) !important;
    cursor: pointer;

}

.news-created-date{
    font-size:10px;
}
/* //////////////////
 */

