#footer {
  color: #fff;
  background: #424242;
  padding: 30px 0;
}
.footer-container {
  width: 80% !important;
  margin: 0 auto !important;
}

.footer-head {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.footer-para {
  font-size: 80%;
  text-align: justify;
}
.footer-list {
  font-size: 16px;
}
.footer-icon {
  margin-bottom: 10px;
  display: flex;
}
.footer-fb,
.footer-insta {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}
.footer-fb {
  margin-right: 10px;
}
.footer-map {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 992px) {
  .footer-head {
    font-size: 18px;
  }
  .footer-container {
    width: 90% !important;
  }
  .footer-list {
    font-size: 16px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 768px) {
  .footer-container {
    width: 100% !important;
  }
  .footer-list {
    font-size: 15px;
  }
  .footer-head {
    margin-bottom: 4px;
  }
}

.web-credit {
  margin: 0 20px;
  background: #fff;
  padding: 10px 0;
  text-align: center;
}
.cre-container {
  background: #fff;
}
.cre-name {
  color: rgba(0, 0, 255, 0.795) !important;
  display: inline;
  text-decoration: none;
}
