.contact-banner {
  height: 60vh;
  width: 100%;
  background: url("../images/gumba/gumba1.jpg") center/cover no-repeat;
  position: relative;
}
.cb-filter{
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgb(0 0 0 / 52%), rgb(51 50 50 / 73%));;
}
.cb-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fffffff2;
}

.cb-head {
  font-size: 48px;
  font-weight: 700;
}
.cb-para {
  font-size: 18px;
}
.cb-btn {
  margin-top: 20px;
}
.cb-btn span {
  cursor: pointer;
  padding: 10px 20px;
  background: #851616;
  border-radius: 5px;
  transition: all 0.6s ease-in;
  text-transform: uppercase;
}
.cb-btn span:hover {
  background: transparent;
  border: 1px solid #851616;
}

.contact-msg {
  background: rgba(217, 217, 217, 0.37);
  padding: 120px;
}
.contact-row {
  width: 80% !important;
  margin: 0 auto !important;
}
.c-phone {
  background: #fff;
  padding: 10px 0;
  color: #232323;
  display: grid;
  grid-template-columns: 20% 60%;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  margin: 20px 0;
}
.c-icon {
  font-size: 20px;
  margin-right: 20px;
  background: #f9d974;
  color: #851616;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  z-index: 9;
}
.c-icon::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: -9;
  height: 50px;
  width: 50px;
  background-color: #f4c51b;
  border-radius: 50%;
}
.c-ph {
  font-size: 19px;
  font-weight: 700;
  text-transform: capitalize;
}

.c-msg {
  background: #fff;
  padding: 40px;
}
.c-msg-head {
  color: #232323;
  text-transform: capitalize;
  font-size: 30px;
  font-weight: 600;
}
.c-msg-para {
  font-size: 14px;
  color: #232323;
}

.c-msg-name {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 20px 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.c-input {
  width: 100%;
  font-size: 18px;
  padding: 5px 10px;
  border: 1px solid grey;
  outline: #851616 !important;
}
.c-msg-box {
  font-size: 18px;

  height: 200px;
  width: 100%;
  padding: 5px 10px;
  outline: #851616 !important;
}
.msg-submit {
  margin: 20px 0;
}
.msg-submit span {
  background: #851616;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s linear;
  border: 1px solid #851616;
  text-transform: capitalize;
}
.msg-submit span:hover {
  background-color: transparent;
  border: 1px solid #851616;
  color: #851616;
}

.contact-map {
  padding: 100px;
}
.map-head {
  text-transform: capitalize;
  font-size: 40px;
  text-align: center;
  color: #851616;
  font-weight: 700;
}
.map-para {
  text-align: center;
  margin-top: 10px;
}
.map-main {
  width: 90%;
  margin: 20px auto;
  height: 500px;
  border: 1px solid grey;
}
.google-map {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .cb-head {
    font-size: 40px;
  }
  .cb-para {
    font-size: 13px;
  }
  .contact-row {
    width: 100% !important;
  }
  .contact-msg {
    padding: 76px 40px;
  }
  .c-phone {
    grid-gap: 23px;
  }
  .map-head {
    font-size: 35px;
  }
}
@media screen and (max-width: 992px) {
  .cb-head {
    font-size: 35px;
  }
  .cb-para {
    font-size: 11px;
  }
  .cb-btn span {
    font-size: 12px;
  }
  .contact-msg {
    padding: 76px 0;
  }

  .c-msg {
    background: #fff;
    padding: 24px;
  }
  .c-icon {
    height: 50px;
    margin-right: 0;
    width: 50px;
  }
  .c-icon::before {
    height: 40px;
    width: 40px;
  }
  .contact-map {
    padding: 60px;
  }
}
@media screen and (max-width: 720px) {
  .c-phone {
    display: flex;
    font-size: 11px;
    flex-direction: column;
    grid-gap: 10px;
  }
  .c-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
  .c-ph {
    font-size: 13px;
    text-align: center;
  }
  .contact-msg {
    padding: 35px 0;
  }
  .c-msg-head {
    font-size: 24px;
  }
  .c-msg-para {
    font-size: 11px;
  }
  .c-input,
  .c-msg-box {
    width: 100%;
    font-size: 13px;
  }
  .c-msg-name {
    grid-gap: 10px;
    margin: 15px 0;
    grid-template-columns: repeat(2, 1fr);
  }
  .c-msg-box {
    width: 100%;
  }
  .c-icon::before {
    height: 35px;
    width: 35px;
  }
  .c-icon {
    height: 45px;
    margin-right: 0;
    width: 45px;
  }
  .c-icon {
    font-size: 16px;
  }
  .map-head {
    font-size: 27px;
  }
  .map-para {
    font-size: 13px;
  }
  .map-main {
    height: 300px;
  }
  .msg-submit span {
    font-size: 13px;
  }
}
@media screen and (max-width: 520px) {
  .cb-btn span {
    padding: 5px 15px;
    font-size: 11px;
  }
  .cb-btn {
    margin-top: 9px;
  }
  .cb-head {
    font-size: 20px;
  }
  .cb-para {
    font-size: 10px;
  }
  .cb-center {
    width: 80%;
  }
  .contact-map {
    padding: 25px 10px;
  }
  .c-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    margin-top: 10px;
  }
  .c-phone {
    margin: 0;
  }
  .map-head {
    font-size: 20px;
  }
  .map-para {
    font-size: 9px;
  }
  .map-main {
    width: 100%;
    height: 215px;
  }
  .c-msg-name {
    margin: 10px 0;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.contact-submit-sucess-message {
  width: 100%;
}
.contact-submit-sucess-message p {
  background-color: rgb(66, 57, 248);
  font-size: 12px;
  padding: 5px 4px;
  border-radius: 3px;
  color: white;
  font-weight: 600;
  text-indent: 10px;
  text-transform: uppercase;
}
