.admin-director-main-container{
    width:100%;
    display: flex;
}
.admin-director-container{
    width:85%;
    display: flex;
}
.admin-director-container > div{
    width:50%;
   
  

}
.admin-director-section-2{
    position:relative;
    
}
.admin-director-section-2 form{
    width:90%;
    margin:auto;
}
.admin-director-section-2 input{
    width:100%;
    margin:10px 0px;
    background-color: rgb(242, 242, 242);
    border:none;
    padding:5px 5px;

}
.admin-director-section-2 > input:focus{
    
}
.admin-director-section-2 textarea{
    width:100%;

    font-size:13px;
    height:48vh;
    font-family: Arial, Helvetica, sans-serif;
    padding:10px;
    background-color: rgb(242, 242, 242);
    border:none;

}



.submit-founder{
    width:100%;
    border:none;
    background-color: purple;
    color:white;
    padding:5px;
    margin:10px 0px;
}
/* //////////////////////////////////////// */
.admin-director-section-1{
    width:100%;
    height:100vh;
    overflow-y: scroll;
    padding:10px;
    position: relative;
}
.admin-director-content{
  
}
.admin-director-content img{
    width:100%;
    height:35vh;
    object-fit: cover;
    border:1px solid grey;
    border:1px solid rgb(211, 211, 211);

    padding:10px;
}
.admin-director-content >h3{
    font-size:18px;
    color:rgb(39, 39, 39);
    padding:10px 0px;
    border:1px solid rgb(211, 211, 211);
    padding:10px;
    margin-top:6px;
    

}
.admin-director-content > p{
    font-size:14px;
    color:rgb(13, 13, 13);
    border:1px solid grey;
    padding:10px;
    border:1px solid rgb(211, 211, 211);


}
.founder-bio{
    border:1px solid grey;
    padding:10px;
}
.director-save-cancle-team,
.director-delete-edit_team{
    position:absolute;
    right:10px;
    z-index:9999999;

    
}
.director-save-cancle-team i,
.director-delete-edit_team i{
    background-color: rgb(255, 103, 103);
    padding:10px;
    margin:0px 10px;
    color:white;
    font-size:20px;
    cursor: pointer;
    transition:all 1s ease;
}

.director-save-cancle-team i:nth-child(1),
.director-delete-edit_team i:nth-child(1){
   background-color: rgb(62, 62, 224);

}
.director-save-cancle-team i:nth-child(1):hover,
.director-delete-edit_team i:nth-child(1):hover{
   background-color: rgb(20, 20, 97);
}
.director-save-cancle-team i:nth-child(2):hover,
.director-delete-edit_team i:nth-child(2):hover{
   background-color: rgb(202, 34, 16);
}
.admin-director-content input{
    width:100%;
    margin:10px 0px;
    padding:5px;
    border:none;
    background-color: rgb(246, 246, 246);

}
.admin-director-content textarea{
    width:100%;
    min-height:55vh;
    padding:10px;
    font-size:13px;
    font-size:1;
    border:none;
    background-color: rgb(246, 246, 246);

    
}


.admin-edit-directory{
    width:40%;
}
.jodit-editor{
    height:100vh;
}