.donation-banner {
  background: url("../images/gumba/gumba4.jpg") center/cover no-repeat;
  height: 70vh;
  width: 100vw;
  position: relative;
}
.donation-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #ffffff00, #f9d974);
  height: 100%;
  width: 100%;
}

.donation-banner-text {
  position: absolute;
  top: 40%;
  left: 70%;
  transform: translate(-50%, -50%);
}
.dona-banner-title {
  font-size: 42px;
  font-weight: 600;
}
.donation-red {
  color: #851616 !important;
}
.dona-banner {
  font-weight: 600;
}
/* //second part */
.dona-about {
  position: relative;
  height: 100vh;
  width: 100vw;
}
.dona-row {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 85% !important;
  margin: 0 auto !important;
  z-index: 1;
  background: #fff;
}
.dona-top {
  background: #851616;
  color: #fff;
  display: flex;
  padding: 7px 0;
}
.help-grid {
  display: grid;
  grid-template-columns: 20% 50%;
  align-items: center;
  justify-content: center;
}
.help-icon {
  font-size: 25px;
}
.help-number {
  color: #ff3535;
  font-size: 18px;
}
.help-text {
  font-size: 20px;
  font-family: Mulish;
}
.right-border {
  border-right: 2px solid #fff;
}
.dona-why {
  color: #454444;
  margin: 10px 0;
  font-size: 22px;
}
.dona-para {
  text-align: justify;
  font-size: 16px;
  background-color: #fff;
}

.dona-img {
  height: 300px;
  width: 100%;
  background: url("../images/gumba/gumba.jpg") center/cover no-repeat;
}
.dona-img1 {
  height: 300px;
  width: 100%;
  background: url("../images/gumba/gumba2.jpg") center/cover no-repeat;
}
/* third */

.dona-more {
  background: #f9d974;
  padding: 100px 20px !important;
  position: relative;
  isolation: isolate;
}
.dona-more-bg {
  clip-path: polygon(0 0, 46% 0, 100% 100%, 0% 100%);
  height: 100%;
  width: 50%;
  background-color: #851616;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.dona-more-img {
  background: url("../images/gumba/gumba1.jpg") center/cover no-repeat;
  height: 500px;
  width: 100%;
  border: 1px solid grey;
  z-index: 99;
}
.dona-more-img-container {
  width: 90% !important;
  margin: 0 auto !important;
  align-items: center;
  justify-content: center;
}
.dona-more-head {
  font-size: 38px;
  font-weight: 700;
  line-height: 44px;
}
.dona-more-para {
  font-size: 17px;
  text-align: justify;
  color: #020202;
  margin: 20px 0;
}

@media screen and (max-width: 1200px) {
  .donation-banner {
    height: 65vh;
  }
  .dona-banner-title {
    font-size: 40px;
    font-weight: 600;
  }
  .help-text {
    font-size: 18px;
  }
  .dona-para {
    font-size: 14px;
  }
  .dona-about {
    height: 90vh;
  }
  .dona-more-head {
    font-size: 32px;
    line-height: 34px;
  }
  .dona-more-para {
    font-size: 14px;
  }
  .dona-more {
    padding: 70px 0px !important;
  }
}
@media screen and (max-width: 992px) {
  .dona-line {
    font-size: 18px;
  }
  .dona-heading {
    font-size: 20px;
  }
  .donation-banner {
    height: 60vh;
  }
  .dona-banner-title {
    font-size: 30px;
    font-weight: 600;
  }
  .help-text {
    font-size: 13px;
  }
  .dona-top {
    padding: 10px 0;
  }
  .dona-more-head {
    font-size: 20px;
    line-height: 23px;
  }
  .dona-more-para {
    font-size: 12px;
  }
  .dona-img {
    height: 250px;
  }
  .dona-more-img {
    height: 400px;
  }
  .dona-row {
    width: 90% !important;
    top: -30px;
  }
  .dona-about {
    height: 120vh;
  }
  .dona-para {
    font-size: 11px;
  }
}
@media screen and (max-width: 720px) {
  .dona-line {
    font-size: 13px;
    width: 100% !important;
  }
  .dona-heading {
    font-size: 18px;
  }
  .donation-banner-text {
    width: 50%;
    top: 56%;
    left: 68%;
  }
  .help-text {
    font-size: 11px;
  }
  .help-icon {
    font-size: 20px;
  }
  .dona-about {
    height: auto;
    padding: 20px 0 !important;
    background: #fde08118;
  }
  .dona-row {
    position: initial;
    transform: none;
  }
  .dona-small {
    padding: 20px 0;
  }
  .dona-more {
    padding: 40px 0px !important;
  }
  .dona-more-head {
    font-size: 17px;
    line-height: 18px;
  }
  .dona-more-para {
    font-size: 12px;
  }
  .home-banner-btn {
    font-size: 10px !important;
  }
  .dona-more-img-container {
    width: 100% !important;
  }
}
@media screen and (max-width: 520px) {
  .dona-line {
    font-size: 12px;
  }
  .dona-heading {
    font-size: 15px;
  }
  .donation-banner {
    height: 35vh;
  }
  .donation-banner-text {
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .help-text {
    font-size: 14px;
  }
  .help-icon {
    font-size: 16px;
  }
  .help-number {
    font-size: 13px;
  }
  .dona-top {
    padding: 10px 0 !important;
  }
  .dona-more {
    padding: 20px 0px !important;
  }
  .dona-more-img {
    height: 400px;
    margin-bottom: 20px;
  }
  .right-border {
    border-right: 1px solid #fff;
  }
  .dona-para,
  .dona-more-para {
    font-size: 14px;
  }
}
.dona-red {
  color: #851616;
}
.dona-line {
  width: 90%;
  margin: 30px auto;
  text-align: justify;
}
.bg-yellow {
  background: #ffbf0061;
}
.li-btn {
  color: #851616;
  padding: 0px 5px;
  border-bottom: 1px solid #851616;
  margin: 0 7px;
  transition: all 0.5s ease-in-out;
}
.li-btn:hover {
  background: #851616;
  color: #fff;
}
