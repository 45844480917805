body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin:0px;
  padding:0px;
  box-sizing: border-box;
}
.admin-main-container{
  display: flex;
}
 /* scroll bar */
 ::-webkit-scrollbar{
  width:0.3em;
  height:0px;
  
}
::-webkit-scrollbar-track{
 background-color: rgb(224, 224, 224);
  border-radius:0vw;
  margin-block:0.1em;
  overflow: hidden;
}
::-webkit-scrollbar-thumb{
  background-color: rgb(84, 84, 84);
  border:.15em solid rgb(57, 57, 57);
  border-radius:100vw;
}
/*  */