#team {
  margin: 100px auto;
}
.team {
  height: 300px;
  position: relative;
  display: grid;
  grid-template-rows: 200px 100px;
  justify-content: center;
}
.team:before {
  content: "";
  position: absolute;
  top: -55px;
  left: 49%;
  height: 55px;
  background-color: #f4c51b;
  width: 4px;
}
.team:after {
  content: "";
  position: absolute;
  top: -55px;
  left: 50%;
  height: 4px;
  width: 400px;
  background-color: #f4c51b;
}

.team-head {
  margin: 0 auto;
  height: 300px;
  width: 250px;
  position: relative;
  display: grid;
  grid-template-rows: 200px 100px;
  justify-content: center;
}
.team-img {
  height: 200px;
  width: 200px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  background: url("../images/news/news3.jpg") center/cover no-repeat;
  position: relative;
  z-index: 1;
}
.team-img-after {
  position: absolute;
  top: 0;
  left: -4px;
  z-index: -99;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  height: 200px;
  width: 200px;
  background: #f9d974;
}
.team-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-size: 20px;
}
.team-post {
  font-size: 80%;
  background-color: #851616;
  color: #fff;
  padding: 10px 30px;
  margin-top: 10px;
}
.team-head:before {
  content: "";
  position: absolute;
  bottom: -470px;
  left: 48%;
  height: 470px;
  width: 4px;
  background: #f4c51b;
}
.team-up-container,
.team-down-container {
  display: grid;
  grid-template-columns: repeat(4, 250px);
  grid-gap: 150px;
  margin-top: 110px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}
.team-up:last-child::after,
.team-down:last-child:after {
  display: none;
}

@media screen and (max-width: 1500px) {
  .team-up-container,
  .team-down-container {
    grid-gap: 120px;
    grid-template-columns: repeat(4, 200px);
  }
  .team:after {
    width: 320px;
  }
  .team-img-after {
    left: -18px;
  }
  .team-head {
    width: 200px;
  }
}
@media screen and (max-width: 1200px) {
  .team-up-container,
  .team-down-container {
    grid-gap: 50px;
    grid-template-columns: repeat(4, 180px);
  }
  .team:after {
    width: 230px;
  }
  .team-img,
  .team-img-after {
    height: 180px;
    width: 180px;
  }
  .team-detail {
    font-size: 18px;
  }
}
@media screen and (max-width: 991px) {
  .team-up-container,
  .team-down-container {
    grid-gap: 30px;
    grid-template-columns: repeat(4, 150px);
    margin-top: 76px;
  }
  .team-img,
  .team-img-after {
    height: 150px;
    width: 150px;
  }
  .team,
  .team-head {
    height: 250px;
    grid-template-rows: 150px 100px;
  }
  .team-head {
    width: 150px;
  }
  .team-detail {
    font-size: 15px;
  }
  .team-post {
    padding: 7px 20px;
  }
  .team:before {
    top: -36px;
    left: 48%;
    height: 38px;
    width: 3px;
  }
  .team::after {
    top: -36px;
    left: 48%;
    width: 183px;
    height: 3px;
  }
  .team-img-after {
    left: -10px;
  }
  .team-head::before {
    height: 367px;
    bottom: -367px;
    width: 3px;
  }
  #team {
    margin: 50px auto;
  }
}
@media screen and (max-width: 720px) {
  .team,
  .team-head {
    height: 160px;
    grid-template-rows: 100px 60px;
  }
  .team-img,
  .team-img-after {
    height: 140px;
    width: 140px;
  }
  .team-detail {
    font-size: 12px;
  }
  .team-post {
    margin-top: 5px;
    padding: 3px 12px;
  }
  .team-up-container,
  .team-down-container {
    grid-gap: 20px;
    grid-template-columns: repeat(4, 100px);
    margin-top: 30px;
  }
  .team-img,
  .team-img-after {
    height: 100px;
    width: 100px;
  }
  .team:before {
    top: -14px;
    left: 48%;
    height: 15px;
    width: 2px;
  }
  .team::after {
    top: -15px;
    left: 48%;
    width: 122px;
    height: 2px;
  }
  .team-head::before {
    height: 208px;
    bottom: -208px;
    width: 2px;
  }
  .team-detail {
    font-size: 11px;
  }
  .team-head {
    height: 160px;
    width: 100px;
  }
}
@media screen and (max-width: 520px) {
  .team-up-container,
  .team-down-container {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 100px);
    margin-top: 30px;
  }
  .team-up:nth-child(2)::after,
  .team-down:nth-child(2)::after {
    display: none;
  }
  .team-head::before {
    height: 572px;
    bottom: -572px;
    width: 2px;
    left: 44%;
  }
  .team::after,
  .team:before {
    top: -10px;
  }
}
