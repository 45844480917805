.news-banner-row {
  padding: 40px 0;
  width: 100%;
  margin: 0 !important;
  background: #ffbf0061;
  justify-content: space-around;
}
.news-head {
  font-size: 130px;
  font-weight: 600;
  color: #851616;
  width: 30% !important;
}
.underline {
  border-bottom: 2px solid #851616;
  padding-bottom: 5px;
}
.news-detail {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.news-title {
  color: #851616;
  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 10px 0;
}
.news-para {
  text-align: justify;
  font-size: 13px;
  color: #020202;
}
.news-second-container {
  position: relative;
  height: 600px;
}
.ns-card-container {
  width: 90% !important;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 0 auto !important;
}
.newsimg-container {
  display: grid;
  grid-template-rows: 250px auto;
  border: 1px solid grey;
  padding: 0 !important;
}
.newsimg-container:nth-child(2) {
  background: #232323;
  color: #fff;
}
.ns-detail {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-img {
  height: 100%;
  width: 100%;
  background: url("../images/news/news3.jpg") center/cover no-repeat;
}
.ns-more {
  font-size: 18px;
}
.ns-para {
  font-size: 16px;
  text-align: justify;
  margin: 10px 0;
}
.news-time {
  font-size: 70%;
}
.ns-icon {
  display: flex;
}
.ns-fb,
.ns-insta {
  color: #851616;
  margin: 0 10px;
  font-size: 140%;
}
.ns-more {
  border: 1px solid #851616;
  padding: 5px 10px;
  background: #851616;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.7s linear;
  text-transform: capitalize;
}
.ns-more:hover {
  color: #851616;
  border: 1px solid #851616;
  background: none;
}
.ns-third-detail {
  width: 90%;
  margin: 20px auto;
  text-align: center;
}
.banner-two {
  height: 60vh;
  width: 100%;
  background: url("../images/news/news3.jpg") center/cover no-repeat;
}
.banner-two-para {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: justify;
}
.last-container {
  width: 90% !important;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 50px auto !important;
}
.sm-margin {
  margin: 10px 0;
}
@media screen and (max-width: 1200px) {
  .newsimg-container {
    display: grid;
    grid-template-rows: 346px auto;
  }
  .ns-para {
    font-size: 13px;
  }
  .news-second-container {
    height: 500px;
  }
}
@media screen and (max-width: 992px) {
  .news-para {
    font-size: 12px;
  }
  .ns-card-container {
    top: -82px;
    width: 98% !important;
  }
  .newsimg-container {
    grid-template-rows: 275px auto;
  }
  .ns-para {
    font-size: 11px;
  }
  .ns-more {
    font-size: 11px;
  }
  .ns-fb,
  .ns-insta {
    font-size: 119%;
  }
  .sm-margin {
    margin: 5px 0 !important;
  }
  .banner-two-para {
    font-size: 14px;
  }
  .news-head {
    font-size: 110px;
  }
}

@media screen and (max-width: 720px) {
  .news-head {
    font-size: 84px;
    display: none;
  }
  .news-detail {
    width: 90%;
  }
  .ns-card-container {
    position: initial;
    transform: none;
    grid-gap: 4px;
    margin: 20px auto !important;
  }
  .news-second-container {
    height: auto;
  }
  .ns-para {
    font-size: 10px;
  }
  .ns-more {
    font-size: 8px;
  }
  .ns-fb,
  .ns-insta {
    font-size: 97%;
  }
  .last-container {
    width: 100% !important;
    grid-gap: 4px;
  }
}

@media screen and (max-width: 520px) {
  .ns-card-container,
  .last-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  .news-head {
    font-size: 30px;
  }
  .news-title {
    font-size: 15px;
  }
  .news-para {
    font-size: 9px;
  }
  .news-banner-row {
    padding: 12px;
  }
  .newsimg-container {
    grid-template-rows: 166px auto;
  }
  .ns-detail {
    padding: 2px 10px;
  }
  .banner-two {
    height: 36vh;
  }
  .banner-two-para {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .ns-third-detail {
    margin: 10px auto;
  }
}

.news-second-container {
  margin-top: 20px !important;
}
