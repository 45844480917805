.student-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.student-card {
  height: 400px;
  border: 1px solid green;
  display: grid;
  grid-template-rows: 70% 30%;
}

.student-img {
  background: url("../images/news/news2.jpg") center/cover no-repeat;
  height: 100%;
  width: 100%;
}
.student-detail {
  font-size: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;
}
.student-post {
  font-size: 80%;
  text-align: center;
  padding: 3px 30px;
  width:100%;
  background: #851616;
  color: #fff;
  margin-top: 7px;
}

@media screen and (max-width: 1200px) {
  .student-card {
    height: 350px;
  }
  .student-detail {
    font-size: 18px;
  }
}
@media screen and (max-width: 992px) {
  .student-card {
    height: 300px;
  }
  .student-grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 720px) {
  .student-card {
    height: 250px;
  }
  .student-detail {
    font-size: 13px;
  }
}
@media screen and (max-width: 520px) {
  .student-card {
    height: 200px;
  }
  .student-detail {
    font-size: 10px;
  }
  .student-grid-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  .student-post {
    padding: 3px 11px;
  }
}
